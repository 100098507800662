import React from 'react';
import { Loading } from '@ui-elements';
import './styles.scss';

interface Props {
  content: string;
}

export const ViewStockPopup: React.FC<Props> = () => {
  return (
    <div id="view-stock-modal" className="fancy-modal">
      <div className="fancy-modal-wrapper">
        <div className="fancy-modal-title">
          <i
            data-close="true"
            className="popup__close fancy-close veiw-stock-close"
            role="button"
          >
            <span>Close</span>
          </i>
          <h2 id="stockPopupTitle">Select your Subaru Model</h2>
          <div className="fancy-modal-content step-1">
            <div className="fancy-modal-body">
              <div id="stock-loader">
                <Loading />
              </div>
              <div id="stock-model" style={{ display: 'none' }}>
                <p>
                  Want one ASAP? Check out some of our first available vehicles.
                </p>
                <a id="viewStock" className="btn">
                  View Stock
                </a>
                <img src="" alt="Subaru" data-test="image:model" />
              </div>
            </div>
          </div>
          <div
            className="fancy-modal-content step-2"
            style={{ display: 'none' }}
          >
            <div className="fancy-modal-body">
              <table className="table-scroll">
                <thead>
                  <tr>
                    {/* <td>MODEL</td> */}
                    <td>VARIANT</td>
                    <td>TRANS</td>
                    <td>COLOUR</td>
                    <td>Availability</td>
                    <td />
                  </tr>
                </thead>
                <tbody className="body-half-screen" id="stocksData" />
              </table>
              <p className="bottom-text">
                <span className="i-alert">!</span> indicates low stock levels
              </p>
              <div className="pagination-container">
                <nav className="data-pagination lg-none">
                  <ul className="stockPagination">
                    <li data-page="prev" className="prev-link">
                      <span>
                        <i className="fa fa-chevron-left" />
                      </span>
                    </li>
                    <li data-page="next" id="prev" className="next-link">
                      <span>
                        <i className="fa fa-chevron-right" />
                      </span>
                    </li>
                  </ul>
                </nav>
              </div>
              <p className="if-your-desired-suba">
                If your desired Subaru <span id="stockModelName">Model</span> is
                not showing as in stock,{' '}
                <a id="popupClose" href="javascript:void(0)">
                  click here
                </a>{' '}
                to build it from scratch.
              </p>
              <p className="bottom-text fw-300">
                Stock availability timeframes are based on third party
                information available to Subaru Australia as at the date of
                publication. Whilst all reasonable efforts have been made to
                ensure accuracy of the stock availability timeframes, they are
                indicative only and subject to change. Your selected vehicle
                preference is not secured until an order has been placed and a
                signed contract has been accepted by Subaru Australia. For more
                information, please read the Contract of Purchase or contact{' '}
                <a href="mailto:buyonline@subaru.com.au">
                  buyonline@subaru.com.au.
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
